/**@jsx jsx*/
import React from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"

import { Base } from "../utilities/tools"

export const TitlePrimary = ({
  fontFamily = "inherit",
  children,
  ...props
}) => {
  const Primary = styled(Base)`
    font-size: 48px;
    font-weight: 300;
    font-family: sans-serif;
    text-align: left;
    font-family: ${fontFamily};
    @media (max-width: 500px) {
      font-size: 30px;
    }
  `
  return (
    <Primary as="h1" {...props}>
      {children}
    </Primary>
  )
}

export const TitleSecondary = ({ children, ...props }) => {
  const Secondary = styled(Base)`
    font-size: 30px;
    font-weight: 300;
    font-family: sans-serif;
    @media (max-width: 500px) {
      font-size: 24px;
    }
  `
  return (
    <Secondary as="h2" {...props}>
      {children}
    </Secondary>
  )
}

export const TitleTertiary = ({ children, ...props }) => {
  const Secondary = styled(Base)`
    font-size: 20px;
    font-weight: 300;
    font-family: sans-serif;@media (max-width: 500px) {
      font-size: 16px;
    }
  `
  return (
    <Secondary as="h2" {...props}>
      {children}
    </Secondary>
  )
}

export const Caption = ({ children, ...props }) => {
  const Description = styled(Base)`
    font-size: 16px;
    font-weight: 300;
    font-family: sans-serif;
  `
  return (
    <Description as="h3" {...props}>
      {children}
    </Description>
  )
}
