const mode = process.env.GATSBY_MODE
let serverURL =
  mode === `dev`
    ? `http://localhost:9999`
    : `https://stark-mountain-04252.herokuapp.com`
let clientURL =
  mode === `dev`
    ? `http://localhost:8000`
    : `https://poqet.io`

export { clientURL, serverURL }
