/** @jsx jsx */
import React from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import { white } from "color-name"

const Container = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(to bottom, white, hsl(216, 45%, 88%));
  display: grid;
  grid-template:
    /* minmax(1rem, 1.5rem)
    minmax(320px, 540px)
    minmax(1.2rem, 1fr)
    / minmax(0.5rem, 1fr) minmax(300px, 768px) minmax(0.5rem, 1fr); */
    minmax(1rem, 1.5rem)
    minmax(auto, 1fr)
    / minmax(0.75rem, 1fr) minmax(auto, 768px) minmax(0.75rem, 1fr);
`

const layout = ({ children, ...props }) => {
  return (
    <>
      <div
        css={css`
          position: absolute;
          top: -150px;
          left: 0;
          background: linear-gradient(to left, #3267ff, #9bfec3);
          height: 300px;
          width: 100vw;
          transform: skewY(178deg);
          z-index: 1;
        `}
      />
      <Container {...props}>{children}</Container>
    </>
  )
}

export default layout
