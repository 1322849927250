/**@jsx jsx */
import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import { navigate } from "gatsby"

import { useMachine } from "@xstate/react"
import deleteConfirmMachine from "../stateMachines/deleteConfirmMachine"
import { useSpring, a } from "react-spring"

const Button = styled.button`
  border: none;
  border-radius: 4px;
  background: linear-gradient(to right, hsl(180, 90%, 40%), #3e8fe8);
  color: white;
  font-weight: 700;
  font-size: 16px;
  padding: 12px 32px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transform: translateY(-2px);
    transform: scale(1.01px);
    box-shadow: 0 8px 24px hsla(0, 0%, 0%, 0.2), 0 5px 6px hsla(0, 0%, 0%, 0.1);
  }
`

export const ButtonPrimary = ({ children, to, ...props }) => {
  const handleClick = () => {
    to && navigate(to)
    props.onClick && props.onClick()
  }

  return (
    <Button {...props} onClick={handleClick}>
      {children}
    </Button>
  )
}

export const ButtonSecondary = ({ children, to, ...props }) => {
  const handleClick = () => {
    to && navigate(to)
    props.onClick && props.onClick()
  }

  return (
    <Button
      css={css`
        padding: 6px 16px;
        font-weight: 500;
      `}
      onClick={handleClick}
      {...props}
    >
      {children}
    </Button>
  )
}

export const ButtonTertiary = ({ children, to, ...props }) => {
  const handleClick = () => {
    to && navigate(to)
    props.onClick && props.onClick()
  }

  return (
    <Button
      css={css`
        padding: 3px 8px;
        background: tomato;
        font-weight: 300;
      `}
      onClick={handleClick}
      {...props}
    >
      {children}
    </Button>
  )
}

const AnimatedButton = a(Button)
const stateStyles = {
  inactive: {},
}
export const ButtonDelete = ({
  children,
  to,
  handleDelete,
  successCallback,
  failureCallback,
  ...props
}) => {
  const [current, send] = useMachine(deleteConfirmMachine)
  const { width, background } = useSpring({
    width: current.context.styles.width,
    background: current.context.styles.background,
  })
  const confirmTimer = setTimeout(() => {
    send("CANCEL")
  }, 4000)
  // Auto cancel delete flow after 3 seconds
  useEffect(() => {
    current.value === "CONFIRM" && confirmTimer()
  }, [current])
  const handleClick = () => {
    switch (current.value) {
      case "inactive":
        send("CLICK")
        break
      case "confirm":
        clearTimeout(confirmTimer)
        handleDelete()
          .then(() => {
            send("CONFIRM")
            setTimeout(() => {
              successCallback()
            }, 1200)
          })
          .catch(() => {
            send("CANCEL")
            failureCallback()
          })
        break
      default:
        console.log("nope")
        return
    }
  }
  return (
    <AnimatedButton
      style={{
        background: background.to(b => b),
      }}
      css={css`
        padding: 3px 8px;
        width: auto;
        height: auto;
        font-weight: 300;
        :hover {
          transform: none;
          box-shadow: none;
        }
      `}
      onClick={handleClick}
      {...props}
    >
      <a.div
        style={{
          width: width.to(w => w),
        }}
      >
        {current.context.title}
      </a.div>
    </AnimatedButton>
  )
}