import { Machine, assign } from "xstate"

export default Machine({
  id: "deleteButton",
  initial: "inactive",
  context: {
    styles: {
      background: `lightgray`,
      width: `12px`,
    },
    title: `x`,
  },
  states: {
    inactive: {
      on: {
        CLICK: {
          target: "confirm",
          actions: [
            assign({
              styles: {
                // background: `linear-gradient(to left, hsla(5, 60%, 62%, 100%), hsla(20, 90%, 62%, 90%))`,
                background: `tomato`,
                width: `55px`,
              },
              title: `confirm`,
            }),
          ],
        },
      },
      meta: {
        background: `blue`,
        width: `12px`,
      },
    },
    confirm: {
      on: {
        CONFIRM: {
          target: "deleted",
          actions: [
            assign({
              styles: {
                background: `hsl(167, 60%, 68%)`,
                width: `12px`,
              },
              title: `✓`,
            }),
          ],
        },
        CANCEL: {
          target: "inactive",
          actions: [
            assign({
              styles: {
                background: `lightgray`,
                width: `12px`,
              },
              title: `x`,
            }),
          ],
        },
      },
    },
    deleted: {
      type: "final",
    },
  },
})
