/**@jsx jsx */
import { css } from "@emotion/core"

// export const large = {
//   mt: 
// }

// export const medium = 

// export const small

export const largeMT = css`
  margin-top: 40px;
  @media (max-width: 500px) {
    margin-top: 32px;
  }
`

export const medMT = css`
  margin-top: 32px;
  @media (max-width: 500px) {
    margin-top: 24px;
  }
`

export const smallMT = css`
  margin-top: 16px;
  @media (max-width: 500px) {
    margin-top: 8px;
  }
`
