/**@jsx jsx*/
import React from "react"
import { css, jsx } from "@emotion/core"

const Card = ({ children, ...props }) => {
  return (
    <div
      css={css`
        position: relative;
        background: white;
        border-radius: 8px;
        padding: 32px 32px;
        height: auto;
        border: none;
        box-shadow: 0 4px 15px hsla(0, 0%, 0%, 0.2),
          0 5px 6px hsla(0, 0%, 0%, 0.1);
        @media (max-width: 500px) {
          padding: 20px 24px;
        }
      `}
      {...props}
    >
      {children}
    </div>
  )
}

export default Card
